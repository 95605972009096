import React, { useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Link from "@material-ui/core/Link";
import Logo from "./images/logo-wordmark_rgb_en_qb.png";
import FooterLogo from "./images/fugro-logo-wh.png";
import Live from "./images/live.jpg";
import Structures from "./images/structures.jpg";
import Backdrop from "./images/backdrop.jpg";
import {
  Divider,
  CardActionArea,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
} from "@material-ui/core";
import GenericDialog from "./GenericDialog";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh", // sticky footer
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  cardMedia: {
    paddingTop: "56.25%", // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  divider: {
    marginTop: "auto", // sticky footer
  },
  footer: {
    display: "flex",
    justifyContent: "center",
    paddingTop: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
  },
  footerLogo: {
    height: "2em",
  },
  appBarLogo: {
    width: "8em",
  },
  header: {
    position: "relative",
    backgroundColor: theme.palette.grey[800],
    color: theme.palette.common.white,
    marginBottom: theme.spacing(4),
    backgroundImage: `url(${Backdrop})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    padding: theme.spacing(8, 0, 6),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  cardActionArea: {
    height: "100%",
  },
}));

const senseLiveApacUrl: string = "https://apac.live.sense.fugro.com";
const senseLiveEuUrl: string = "https://eu.live.fugro.com";
const senseLiveMeiUrl: string = "https://mei.live.sense.fugro.com";
const senseLiveUrls: string[] = [senseLiveApacUrl, senseLiveEuUrl, senseLiveMeiUrl];

const senseLiveUrlsMap: { [url: string]: string } = {};
senseLiveUrlsMap[senseLiveApacUrl] = "APAC";
senseLiveUrlsMap[senseLiveEuUrl] = "EU";
senseLiveUrlsMap[senseLiveMeiUrl] = "MEI";

const senseStructuresUrls: string[] = [
  "https://cooper.structures.sense.fugro.com/",
  "https://eni.structures.sense.fugro.com/",
  "https://omv.structures.sense.fugro.com/",
  "https://santos.structures.sense.fugro.com/",
  "https://wel.structures.sense.fugro.com/"
];
const senseStructuresUrlMap: { [url: string]: string } = {};

senseStructuresUrlMap["https://cooper.structures.sense.fugro.com/"] = "Cooper";
senseStructuresUrlMap["https://eni.structures.sense.fugro.com/"] = "ENI";
senseStructuresUrlMap["https://omv.structures.sense.fugro.com/"] = "OMV";
senseStructuresUrlMap["https://santos.structures.sense.fugro.com/"] = "Santos";
senseStructuresUrlMap["https://wel.structures.sense.fugro.com/"] = "Woodside";

export default function App() {
  const classes = useStyles();
  const [openSenseLiveDialog, setOpenSenseLiveDialog] = useState(false);
  const [openSenseStructuresDialog, setOpenSenseStructuresDialog] = useState(
    false
  );

  const cards = [
    {
      id: 1,
      name: "Sense.Live",
      description:
        "Sense.Live is Fugro's custom built cloud based content deliver network providing the ability to stream video to a clean and user friendly portal giving real-time access to the live feeds.",
      image: Live,
      openHandler: () => setOpenSenseLiveDialog(true),
    },
    {
      id: 2,
      name: "Sense.Structures Insights",
      description:
        "Sense.Structures Insights allows clients to interact with captured structures inspection data.",
      image: Structures,
      openHandler: () => setOpenSenseStructuresDialog(true),
    },
  ];

  const [senseLiveUrl, setSenseLiveUrl] = useState<string>(senseLiveApacUrl);
  const [senseStructuresUrl, setSenseStructuresUrl] = useState<string>("");

  const handleGoToSenseLive = () => {
    setOpenSenseLiveDialog(false);
    window.open(senseLiveUrl, "_blank"); //to open new page
  };

  const handleSenseLiveUrlChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setSenseLiveUrl(event.target.value as string);
  };

  const handleGoToSenseStructures = () => {
    setOpenSenseLiveDialog(false);
    window.open(senseStructuresUrl, "_blank"); //to open new page
  };

  const handleSenseStructuresUrlChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setSenseStructuresUrl(event.target.value as string);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="relative">
        <Link color="textPrimary" href="https://www.fugro.com/">
          <Toolbar>
            <img
              src={FooterLogo}
              className={classes.appBarLogo}
              alt="FugroLogo"
            />
          </Toolbar>
        </Link>
      </AppBar>
      <main>
        <div className={classes.header}>
          <Container maxWidth="sm">
            <Typography
              component="h1"
              variant="h2"
              align="center"
              color="inherit"
              gutterBottom
            >
              Sense Suite
            </Typography>
            <Typography variant="h5" align="center" color="inherit" paragraph>
              It makes Sense to conduct offshore inspections, onshore.
            </Typography>
          </Container>
        </div>
        <Container className={classes.cardGrid} maxWidth="md">
          <Grid container spacing={4}>
            {cards.map((card) => (
              <Grid item key={card.id} xs={12} sm={6} md={6}>
                <CardActionArea
                  className={classes.cardActionArea}
                  onClick={card.openHandler}
                >
                  <Card className={classes.card}>
                    <CardMedia
                      className={classes.cardMedia}
                      image={card.image}
                      title={card.name}
                    />
                    <CardContent className={classes.cardContent}>
                      <Typography gutterBottom variant="h5" component="h2">
                        {card.name}
                      </Typography>
                      <Typography>{card.description}</Typography>
                    </CardContent>
                  </Card>
                </CardActionArea>
              </Grid>
            ))}
          </Grid>
        </Container>
      </main>
      <Divider className={classes.divider} />
      <footer className={classes.footer}>
        <Typography
          align="center"
          color="textPrimary"
          variant="subtitle1"
          gutterBottom={true}
        >
          {"\u00A9 "}
          <Link color="textPrimary" href="https://www.fugro.com/">
            Fugro {new Date().getFullYear()}
          </Link>
          {", powered by "}
        </Typography>
        <img src={Logo} className={classes.footerLogo} alt="Fugro" />
      </footer>
      <GenericDialog
        open={openSenseLiveDialog}
        title={"Please select your Sense.Live region"}
        message={""}
        onClose={() => setOpenSenseLiveDialog(false)}
        okButtonText={"OK"}
        okButtonDisabled={false}
        okHandler={handleGoToSenseLive}
        cancelHandler={() => setOpenSenseLiveDialog(false)}
      >
        <FormControl className={classes.formControl}>
          <InputLabel id="simple-select-label">Region</InputLabel>
          <Select
            labelId="simple-select-label"
            id="simple-select"
            value={senseLiveUrl}
            onChange={handleSenseLiveUrlChange}
          >
            {senseLiveUrls.map((url) => (
              <MenuItem key={url} value={url}>
                {senseLiveUrlsMap[url]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </GenericDialog>

      <GenericDialog
        open={openSenseStructuresDialog}
        title={"Please select your Sense.Structures Insights client"}
        message={""}
        onClose={() => setOpenSenseStructuresDialog(false)}
        okButtonText={"OK"}
        okButtonDisabled={false}
        okHandler={handleGoToSenseStructures}
        cancelHandler={() => setOpenSenseStructuresDialog(false)}
      >
        <FormControl className={classes.formControl}>
          <InputLabel id="simple-select-label">Client</InputLabel>
          <Select
            labelId="simple-select-label"
            id="simple-select"
            value={senseStructuresUrl}
            onChange={handleSenseStructuresUrlChange}
          >
            {senseStructuresUrls.map((url) => (
              <MenuItem key={url} value={url}>
                {senseStructuresUrlMap[url]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </GenericDialog>
    </div>
  );
}
